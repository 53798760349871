import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data && node.data.target) {
        const url = node.data.target.file.url
        return (
          <div className="richImgWrapper">
            <img src={url} alt="" />
          </div>
        )
      } else {
        return null
      }
    },
  },
}

const PageWrapper = styled.div`
  margin: 80px 0 80px 0;
  .headerWrapper {
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 400;
      font-size: 32px;
      color: #181818;
      margin-bottom: 20px;
    }
    .cat {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 140px;
      max-width: calc(100% - 30px);
      min-height: 42px;
      padding: 10px 15px;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      background: #7497b2;
      border-radius: 5px;
    }
    .date {
      font-weight: 300;
      font-size: 16px;
      color: #646464;
      margin-bottom: 0;
      padding-left: 15px;
    }
    .excerpt {
      padding-left: 30px;
      font-size: 16px;
      color: #646464;
      padding-bottom: 20px;
    }
  }
  .bodyRichText {
    position: relative;
    margin-top: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #dad5cf;
    border-bottom: 1px solid #dad5cf;
    hr {
      margin: 35px 0;
      color: #dad5cf;
      border-top: 0 solid #dad5cf;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      color: #181818;
      margin-left: 0;
    }
    ol,
    ul,
    blockquote,
    p {
      font-family: "Kanit", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #646464;
      margin-left: 0;
    }
    ol,
    ul {
      li {
        p {
          margin-left: 0 !important;
        }
      }
    }
    blockquote {
      background: #7497b2;
      padding: 30px;
      p {
        font-weight: 400;
        color: #fff;
        margin-left: 0 !important;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .richImgWrapper {
      margin: 35px auto;
      text-align: right;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .shareWrapper {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 30px;
    .excerptCard {
      width: 100%;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      p {
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        margin-bottom: 10px;
        margin-left: 0 !important;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      b,
      strong {
        font-weight: 700;
      }
    }
  }
  .joinUsWrapper {
    margin-top: 50px;
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 20px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      color: #181818;
      margin-bottom: 20px;
      a {
        font-weight: 700;
        color: #646464;
      }
    }
  }
  .relatedArticles {
    margin-top: 15px;
    .header {
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #181818;
      margin-bottom: 25px;
    }
  }

  @media only screen and (min-width: 576px) {
    .headerWrapper {
      .title {
        font-size: 34px;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    margin: 100px 0 80px 0;
    .headerWrapper {
      .title {
        font-size: 36px;
      }
    }
    .bodyRichText {
      h2,
      h3,
      h4,
      h5,
      h6,
      ol,
      ul,
      blockquote,
      p {
        margin-left: 250px;
      }
    }
    .shareWrapper {
      position: absolute;
      top: 37px;
      left: 0;
      .excerptCard {
        width: 220px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .headerWrapper {
      .title {
        font-size: 38px;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    .bodyRichText {
      h2,
      h3,
      h4,
      h5,
      h6,
      ol,
      ul,
      blockquote,
      p {
        margin-left: 150px;
      }
    }
    .shareWrapper {
      .excerptCard {
        width: 235px;
      }
    }
  }
`
const RelatedCard = styled.div`
  background: #fff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: 100%;
  min-height: 100%;
  &:hover {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
  }
  .featuredWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    a {
      display: inline-flex;
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .contentWrapper {
    padding: 25px 20px;
    font-family: "Kanit", sans-serif;
    .date {
      font-weight: 300;
      font-size: 18px;
      color: #7497b2;
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: 18px;
      color: #181818;
      margin-bottom: 0;
    }
  }
`

const JobsSinglePage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { coverFields, singleJobs, relatedJobs, site } = data
  const { titleAlt, defaultDescription } = site.siteMetadata
  const { coverImage } = coverFields.edges[0].node
  const getCoverImage = getImage(coverImage)
  const { title, type, createdAt, body } = singleJobs.edges[0].node

  return (
    <Layout>
      <Seo
        lang={locale}
        title={`${title} | ${titleAlt}`}
        description={defaultDescription}
      />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={title}
        secondLink="/work-with-us/"
        secondText={intl.formatMessage({ id: "navMenu6" })}
      />
      <PageWrapper>
        <div className="gb-container">
          <Row>
            <Col xs={24} sm={20} md={18} lg={14} xl={12}>
              <div className="headerWrapper">
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                {/* <div className="excerpt">
                    {renderRichText(excerpt, richTextOptions)}
                </div> */}
                <p className="cat">{type}</p>
                <p className="date">{createdAt}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[80, 40]}>
            <Col xs={24} xl={16}>
              <div className="bodyRichText">
                {renderRichText(body, richTextOptions)}
              </div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="relatedArticles">
                <p className="header">
                  {intl.formatMessage({ id: "textJobVacancies" })}
                </p>
                <Row gutter={[16, 16]}>
                  {relatedJobs &&
                    relatedJobs.edges.map(related => {
                      return (
                        <Col key={related.node.slug} xs={24} sm={12} xl={24}>
                          <RelatedCard>
                            <div className="contentWrapper">
                              <p className="date">{related.node.type}</p>
                              <Link to={`/jobs/${related.node.slug}/`}>
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: related.node.title,
                                  }}
                                />
                              </Link>
                            </div>
                          </RelatedCard>
                        </Col>
                      )
                    })}
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={[80, 40]}>
            <Col xs={24} xl={24}>
              <div className="joinUsWrapper">
                <p className="title">ร่วมงานกับเรา</p>
                <p className="text">
                  สนใจร่วมงาน เพื่อร่วมพัฒนาองค์กร โปรดกรอกชื่อ-สกุล,
                  เบอร์ติดต่อ, และตำแหน่งงานที่ต้องการ พร้อมแนบประวัติส่วนตัว
                  (Resume) มาที่อีเมล{" "}
                  <a
                    href="mailto:recruit-tsb@tsbsteel.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    recruit-tsb@tsbsteel.com
                  </a>{" "}
                  ทางบริษัทจะพิจารณาคุณสมบัติผู้สมัคร
                  ในด้านความรู้ความสามารถและประสบการณ์ หากมีตำแหน่งงานที่เหมาะสม
                  ทางบริษัทจะพิจารณา และติดต่อกลับในภายหลัง
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
      <Footer />
    </Layout>
  )
}

export default injectIntl(JobsSinglePage)

export const query = graphql`
  query JobsSingleQuery($slug: String!, $locale: String, $language: String) {
    coverFields: allContentfulWorkWithUsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    singleJobs: allContentfulJobsPosts(
      filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          title
          type
          createdAt(formatString: "DD MMM YYYY", locale: $language)
          body {
            raw
          }
          excerpt {
            raw
          }
        }
      }
    }
    relatedJobs: allContentfulJobsPosts(
      filter: { slug: { ne: $slug }, node_locale: { eq: $locale } }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          title
          slug
          type
        }
      }
    }
    site {
      siteMetadata {
        titleAlt
        defaultDescription
      }
    }
  }
`
